import request from '@/utils/request'

// 初始化事件演化数据
export function initEventEvolution (data) {
  return request({
    url: '/eventEvolution/selectAllEventEvolution',
    method: 'POST',
    data
  })
}
// 添加事件演化
export function addEventEvolution (data) {
  return request({
    url: '/eventEvolution/saveEventEvolution',
    method: 'POST',
    data
  })
}
// 删除事件演化
export function delEventEvolution (data) {
  return request({
    url: '/eventEvolution/delectEventEvolution',
    method: 'POST',
    data
  })
}
// 回显事件演化的数据
export function showEventEvolution (data) {
  return request({
    url: '/eventEvolution/selectByIdEventEvolution',
    method: 'POST',
    data
  })
}
// 事件演化修改
export function updateEventEvolution (data) {
  return request({
    url: '/eventEvolution/updateEventEvolution',
    method: 'POST',
    data
  })
}
// 事件演化详情
export function eventEvolutionDetails (data) {
  return request({
    url: '/eventEvolution/eventEvolutionDetails',
    method: 'POST',
    data
  })
}
// 舆情走势
export function getOpinionDirection (data) {
  return request({
    url: '/eventEvolution/getOpinionDirection',
    method: 'POST',
    data
  })
}
// 媒体排行
export function mediaRanking (data) {
  return request({
    url: '/eventEvolution/mediaRanking',
    method: 'POST',
    data
  })
}
// 关键词创建
export function saveEvolutionKeyword (data) {
  return request({
    url: '/evolutionKeyword/saveEvolutionKeyword',
    method: 'POST',
    data
  })
}
// 获取关键词列表
export function selectAllEvolutionKeyword (data) {
  return request({
    url: '/evolutionKeyword/selectAllEvolutionKeyword',
    method: 'POST',
    data
  })
}
// 删除关键词
export function deleteEvolutionKeyword (data) {
  return request({
    url: '/evolutionKeyword/deleteEvolutionKeyword',
    method: 'POST',
    data
  })
}
// 舆情分布统计图
export function publicOpinionDistribution (data) {
  return request({
    url: '/eventEvolution/publicOpinionDistribution',
    method: 'POST',
    data
  })
}
export function addNewsToEvent (data) {
  return request({
    url: '/eventEvolution/addNewsToEvent',
    method: 'POST',
    data
  })
}
// 审核列表 显示未审核未通过的
export function reviewList (data) {
  return request({
    url: '/eventEvolution/reviewList',
    method: 'POST',
    data
  })
}

// 通过 拒绝按钮
export function incidentReview (data) {
  return request({
    url: '/eventEvolution/incidentReview',
    method: 'POST',
    data
  })
}
// 删除事件演化中的新闻
export function deleteEventMap (data) {
  return request({
    url: '/eventEvolution/delEventNews',
    method: 'POST',
    data
  })
}
// 导出
export function dataExcelExport (data) {
  return request({
    url: '/eventEvolution/eventEvolutionDataExcelExport',
    method: 'POST',
    data
  })
}
