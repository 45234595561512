<template>
  <section class="add-event">
    <h2 class="title">添加事件</h2>
    <el-form label-width="80px" :model="form">
      <el-form-item label="事件名称">
        <el-input v-model="form.name" placeholder="请输入事件名称"></el-input>
      </el-form-item>
      <el-form-item label="所属项目">
        <el-select style="width: 100%" v-model="form.projectId" placeholder="请选择对应终端项目">
          <el-option
            v-for="item in cpArray"
            :key="item.id"
            :label="item.pname"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="创建日期">-->
<!--        <el-date-picker-->
<!--          v-model="form.createDate"-->
<!--          type="datetime"-->
<!--          value-format="yyyy-MM-dd HH:mm:ss">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
      <el-form-item label="起始时间">
        <el-date-picker placeholder="请设置起始时间"
          v-model="form.startDate"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker placeholder="请设置结束时间"
          v-model="form.endDate"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="核心词">
        <el-input type="textarea" :rows="5" v-model="form.keyword" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item label="同现词">
        <el-input type="textarea" :rows="5" v-model="form.cooccurrenceWord" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item label="排除词">
        <el-input type="textarea" :rows="5" v-model="form.excludeWord" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item class="btn-wrap">
        <el-button @click="goBack">返回</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { addEventEvolution } from '@/api/eventEvolution'
import { listCP } from '@/api/cProject'
export default {
  data () {
    return {
      form: {
        name: '',
        createDate: '',
        startDate: '',
        endDate: '',
        keyword: '',
        cooccurrenceWord: '',
        excludeWord: '',
        projectId: ''
      },
      cpArray: []
    }
  },
  mounted () {
    this.fetchCPList()
  },
  methods: {
    async fetchCPList () {
      const res = await listCP()
      this.cpArray = res.data
    },
    async onSubmit () {
      try {
        if (!this.form.name || !this.form.startDate || !this.form.endDate || !this.form.keyword || !this.form.projectId) {
          this.$message.warning('请检查除同现词和排除词外是否有未填项')
          return
        }
        const res = await addEventEvolution(this.form)
        if (res.code === 0) {
          this.$message.success('添加成功')
        } else {
          this.$message.error(res.msg)
        }
      } catch (err) {
        this.$message.error('添加失败')
      }
      this.$router.back()
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss">
.add-event {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
  border-radius: 10px;
  padding: 40px 30px;
  .title {
    font-size: 18px;
    color: #333333;
    margin: 0;
  }
  .el-form {
    margin-top: 30px;
    .el-input {
      width: 480px;
    }
    .btn-wrap {
      margin-top: 70px;
    }
  }
}
</style>
