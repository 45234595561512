import request from '@/utils/request'
export function listCP () {
  return request({
    url: '/cproject/list',
    method: 'POST'
  })
}
export function listMS () {
  return request({
    url: '/cproject/ms',
    method: 'GET'
  })
}
export function addCP (data) {
  return request({
    url: '/cproject/add',
    method: 'POST',
    data
  })
}
export function setEnable (data) {
  return request({
    url: '/cproject/setEnable',
    method: 'POST',
    data
  })
}
export function setAllowAdd (data) {
  return request({
    url: '/cproject/setAllowAdd',
    method: 'POST',
    data
  })
}
export function getDetail (data) {
  return request({
    url: '/cproject/detail',
    method: 'POST',
    data
  })
}
export function updateCP (data) {
  return request({
    url: '/cproject/update',
    method: 'POST',
    data
  })
}
export function deleteCP (data) {
  return request({
    url: '/cproject/delete',
    method: 'POST',
    data
  })
}
